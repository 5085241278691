import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { TokenKeys } from '@vezham/ui/dist/contracts/iam'

import useBusiness from '@store/hq/business/vstore'
import usePeople from '@store/hq/people/vstore'

import { sendMessage } from '../utils'

export const resetAllToken = () => {
  usePeople.reset()
  useBusiness.reset()
  sendMessage({ method: 'RESET_ALL_TOKEN' })
}

export const resetBusinessToken = () => {
  useBusiness.reset()
  sendMessage({ method: 'RESET_BUSINESS_TOKEN' })
}

export const saveAndSendAccountDCToIAM = (dataCenter: string) => {
  if (dataCenter) {
    useVStore.set(TokenKeys.ACCOUNT_DC, dataCenter)
    sendMessage({ method: 'SAVE_ACCOUNT_DC', data: dataCenter })
  } else {
    resetBusinessToken()
  }
}
