import React, { lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { IS_PRODUCTION } from '@vezham/ui/dist/contracts/constants'
import { init as Store } from '@vezham/ui/dist/contracts/hooks/useVStore'
import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { StoreKeys } from '@vezham/ui/dist/contracts/iam'
import { init as Worker } from '@vezham/ui/dist/contracts/serviceworker'
import { ThemeProvider } from '@vezham/ui/dist/contracts/theme'

import '@utils/axios'
import { init as IAM, onIAMmessenge } from '@utils/iam'

import App from '@/App'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000
    }
  }
})

const ReactQueryDevtools = lazy(() =>
  import('react-query/devtools').then(d => ({
    default: d.ReactQueryDevtools
  }))
)

const searchParams = new URLSearchParams(window.location.search)
const redirectFrom = searchParams.get(StoreKeys.REDIRECTED_FROM)
redirectFrom && useVStore.set(StoreKeys.REDIRECTED_FROM, redirectFrom)

const mountApp = () => {
  const el = document.getElementById('root')
  if (!el.getAttribute('vapp-mounted')) {
    const root = ReactDOM.createRoot(el)
    root.render(
      <QueryClientProvider client={queryClient}>
        {IS_PRODUCTION ? null : <ReactQueryDevtools />}
        <BrowserRouter>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    )
    el.setAttribute('vapp-mounted', 'accounts')
  }
}

window.onmessage = onIAMmessenge(mountApp)
Store({ pretext: 'accounts' })
IAM()
Worker({})
