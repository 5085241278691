import React, { Suspense, lazy } from 'react'
import { RouteObject, useNavigate, useRoutes } from 'react-router-dom'

import Loading from '@vezham/ui/dist/organisms/loading'

import OnBoarding from './onboarding'

export enum AppRoutes {
  Home = '/',
  NotFound = '/not-found',
  Welcome = '/welcome',
  SignUp = '/signup',
  LogIn = '/login'
}

let childrenRoutes: RouteObject[] = [].concat({
  children: [...OnBoarding]
})

const Home = lazy(() => import(/* webpackChunkName: "Home" */ '@pages/home'))
const NotFound = lazy(
  () =>
    import(
      /* webpackChunkName: "NotFound" */ '@vezham/ui/dist/organisms/notFound'
    )
)

export const Routers = () => {
  const navigate = useNavigate()

  return (
    <Suspense fallback={<Loading />}>
      {useRoutes([
        {
          path: AppRoutes.Home,
          children: [
            {
              index: true,
              element: <Home />
            },
            ...childrenRoutes
          ]
        },
        {
          path: '*',
          element: (
            <NotFound
              pathname={window.location.pathname}
              onClick={() => navigate(AppRoutes.Home)}
            />
          )
        }
      ])}
    </Suspense>
  )
}
